import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthenticated()
      .pipe(
        map(isAuthenticated =>  {
          return isAuthenticated
            ? true
            : router.parseUrl(`/auth/login?returnUrl=${state.url}`);
        })
      );
}
