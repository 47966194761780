import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  private _isRefreshing = false;
  private _refreshTokenSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private _authService: AuthService
  ) { }

  private addToken(request: HttpRequest<any>, authToken: string): HttpRequest<any> {
    return request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/api/auth/signout')) {
      return EMPTY;
    }

    if (request.url.endsWith('/api/auth/refresh')) {
      return this._authService.signOut()
        .pipe(
          switchMap(_ => {
            return EMPTY;
          }),
          catchError(_ => {
            return EMPTY;
          })
        );
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._refreshTokenSubject.next(undefined);

      return this._authService.refreshToken()
        .pipe(
          switchMap(tokens => {
            this._isRefreshing = false;
            this._refreshTokenSubject.next(tokens.authToken);
            return next.handle(this.addToken(request, tokens.authToken));
          })
        );
    }
    else
    {
      return this._refreshTokenSubject.pipe(
        take(1),
        filter(token => !!token),
        switchMap(token => {
          return next.handle(this.addToken(request, token!));
        })
      );
    }
  }

  private isSameOriginUrl(req: any): boolean {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this._authService.getAuthToken();

    if (!!authToken && this.isSameOriginUrl(request)) {
      request = this.addToken(request, authToken);
    }

    return next.handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(() => error);
        }
      }));
  }
}
