import { ErrorHandler, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { SettingsModel } from '../settings/settings.model';
import rg4js from 'raygun4js';
import versionData from '../../../environments/version.json';

@Injectable({ providedIn: 'root' })
export class RaygunErrorHandler implements ErrorHandler {

  constructor(
    private readonly _logger: NGXLogger,
    private readonly _settings: SettingsService
  ) {
    this._logger.info('Version', versionData.version);
    this._init();
  }

  private _init() {
    this._settings.getSettings()
      .pipe(take(1))
      .subscribe((settings: SettingsModel) => {
        rg4js('apiKey', settings.raygunKey);
        rg4js('setVersion', versionData.version);

        if (environment.production) {
          rg4js('enableCrashReporting', true);
          this._logger.debug('RaygunErrorHandler: Raygun enabled');
        }
      });
  }

  handleError(error: any): void {
    this._logger.error(error);

    if (environment.production) {
      rg4js('send', error);
    }
  }

}
